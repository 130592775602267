import React from 'react'
import ReactDOM from 'react-dom'

import Button from '@components/Button'
import Link from '@components/Link'
import PaddedBlock from '@components/PaddedBlock'
import SEO from '@components/SEO'
import Title from '@components/Title'
import Sea from '@utils/Sea'

import styles from './404.module.css'

class NotFoundPage extends React.PureComponent {
  componentDidMount() {
    this.sea = new Sea()
    this.sea.initialize(ReactDOM.findDOMNode(this))
  }
  componentWillUnmount() {
    this.sea.destroy()
  }
  render() {
    return (
      <div className={styles.root}>
        <SEO title="404: Not found" />

        <PaddedBlock className={styles.content}>
          <Title first className={styles.title}>
            Oh snap!
            <br />
            404 Not Found
          </Title>
          <p>
            The requested page wasn't found on the <strike>Nile</strike> Server.
          </p>
          <p>
            <Link to="/reservation">Book a cruise</Link> with us and we will surely help you find what you are missing!
          </p>
          <Button className={styles.button} to="/" scrollToMainContent={false}>
            Back to Homepage
          </Button>
        </PaddedBlock>
      </div>
    )
  }
}

export default NotFoundPage

const THREE = require('three')

const skyColor = 0xe0effd
const seaColor = 0x607257
const textureSize = 32

class Sea {
  initialize = (element = document.body) => {
    this.camera = new THREE.PerspectiveCamera(60, window.innerWidth / window.innerHeight, 1, 20000)
    this.camera.position.y = 200
    this.clock = new THREE.Clock()
    this.scene = new THREE.Scene()
    this.scene.background = new THREE.Color(skyColor)
    this.scene.fog = new THREE.FogExp2(skyColor, 0.0007)
    this.geometry = new THREE.PlaneBufferGeometry(20000, 20000, textureSize - 1, textureSize - 1)
    this.geometry.rotateX(-Math.PI / 2)
    const position = this.geometry.attributes.position
    position.dynamic = true
    for (let i = 0; i < position.count; i++) {
      position.setY(i, 35 * Math.sin(i / 2))
    }
    const texture = new THREE.TextureLoader().load(require('@images/water.jpg'))
    texture.wrapS = texture.wrapT = THREE.RepeatWrapping
    texture.repeat.set(5, 5)
    const material = new THREE.MeshBasicMaterial({ color: seaColor, map: texture })
    const mesh = new THREE.Mesh(this.geometry, material)
    this.scene.add(mesh)
    this.renderer = new THREE.WebGLRenderer({ antialias: true })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    element.appendChild(this.renderer.domElement)
    this.animate()
    window.addEventListener('resize', this.handleResize, false)
  }
  destroy = () => {
    window.removeEventListener('resize', this.handleResize, false)
  }
  handleResize = () => {
    const { innerWidth, innerHeight } = window
    this.camera.aspect = innerWidth / innerHeight
    this.camera.updateProjectionMatrix()
    this.renderer.setSize(innerWidth, innerHeight)
  }
  animate = () => {
    requestAnimationFrame(this.animate)
    this.render()
  }
  render() {
    const time = this.clock.getElapsedTime() * 5
    const position = this.geometry.attributes.position
    for (let i = 0; i < position.count; i++) {
      position.setY(i, 35 * Math.sin(i / 5 + (time + i) / 7))
    }
    position.needsUpdate = true
    this.renderer.render(this.scene, this.camera)
  }
}

export default Sea
